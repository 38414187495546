import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import RoundButton from '../round-button';
import { getDamageArea } from '../utils';
import './styles.module.scss';
import { Damage, DamageTypes } from '../interfaces';

interface DamageWindowProps {
	show: boolean;
	areaId: number;
	damageTypes: string[];
	damage: Damage;
	showOtherOption?: boolean;
	otherOptionPlaceholder?: string;
	version: 1 | 2 | undefined;
	close: () => void;
	onConfirm: (areaId: number, damageTypes: string[], damage?: Damage) => void;
}

export const DAMAGE_TYPES = [DamageTypes.SCRATCH, DamageTypes.DENT, DamageTypes.MECHANICAL_DAMAGE, DamageTypes.BROKEN];

export function buildUpdateStateFn(params: {
	damageTypes: string[];
	setDamageTypes: any;
	damage: Damage;
	setDamage: any;
	areaId: number;
	version?: 1 | 2;
}) {
	return (damageType: string): void => {
		const newDamageTypes = [...params.damageTypes];
		const index = params.damageTypes.indexOf(damageType);
		if (index === -1) {
			newDamageTypes.push(damageType);
		} else {
			newDamageTypes.splice(index, 1);
		}

		const newDamage: Damage = { ...params.damage, damageAreaId: params.areaId };
		switch (damageType) {
			case DamageTypes.SCRATCH:
				newDamage.scratched = !newDamage.scratched;
				break;
			case DamageTypes.DENT:
				newDamage.dented = !newDamage.dented;
				break;
			case DamageTypes.MECHANICAL_DAMAGE:
				newDamage.mechanicalDamage = !newDamage.mechanicalDamage;
				break;
			case DamageTypes.BROKEN:
				newDamage.broken = !newDamage.broken;
				break;
		}

		params.setDamage(newDamage);
		params.setDamageTypes(newDamageTypes);
	};
}

const DamageWindow = (props: DamageWindowProps): JSX.Element => {
	const windowRef: any = useRef(null);
	// We need internal/disposable UI state, to allow the user to consider
	// options before actually effectuating changes to the global state. That
	// is, the user might make selection changes but then close (in which case)
	// we _do not_ want the changes to have taken place in global state.
	const [internalDamageTypes, setInternalDamageTypes] = useState<string[] | null>(props.damageTypes);
	const [internalDamage, setInternalDamage] = useState<Damage>(props.damage || {});

	// If internal-state is set, use it. Else, fallback to selected global state.
	const damageTypes = internalDamageTypes || props.damageTypes || [];
	const check = buildUpdateStateFn({
		damageTypes,
		setDamageTypes: setInternalDamageTypes,
		damage: internalDamage,
		setDamage: setInternalDamage,
		version: props.version,
		areaId: props.areaId,
	});
	const onClick = (): void => {
		props.onConfirm(props.areaId, damageTypes, internalDamage); // if empty string, return undefined instead
	};

	// If hiding, clear internal state
	useEffect(() => {
		if (!props.show) {
			setInternalDamageTypes(null);
		}

		// Verifies of click is outside of damage-window element and closes the modal.
		const handleOutsideClick = (ev: MouseEvent): void => {
			if (!windowRef.current.contains(ev.target)) {
				props.close();
			}
		};

		document.addEventListener('mousedown', handleOutsideClick);

		return (): void => document.removeEventListener('mousedown', handleOutsideClick);
	}, [props]);

	const getDamageValue = (damageType: DamageTypes) => {
		switch (damageType) {
			case DamageTypes.SCRATCH:
				return internalDamage.scratched;
			case DamageTypes.DENT:
				return internalDamage.dented;
			case DamageTypes.MECHANICAL_DAMAGE:
				return internalDamage.mechanicalDamage;
			case DamageTypes.BROKEN:
				return internalDamage.broken;
			default:
				return false;
		}
	};

	return (
		<div className={clsx('damagesModal', { 'damagesModal--hidden': !props.show })}>
			<div className="damagesModal__content" ref={windowRef}>
				<h4 className="damagesModal__title">
					{getDamageArea(props.areaId)} ({props.areaId})
				</h4>
				<h4 className="damagesModal__subtitle">Seleccione daños</h4>
				<div className="damagesModal__options">
					{DAMAGE_TYPES.map((damageType) => (
						<RoundButton
							key={damageType}
							checked={Number(props.version) >= 2 ? getDamageValue(damageType) : damageTypes.includes(damageType)}
							onClick={(): void => check(damageType)}
						>
							{damageType}
						</RoundButton>
					))}
					{props.showOtherOption && (
						<textarea
							className="other-input"
							rows={4}
							value={internalDamage.otherDamages}
							placeholder={props.otherOptionPlaceholder || 'Otros daños'}
							onChange={({ target }) => setInternalDamage({ ...internalDamage, otherDamages: target.value })}
						/>
					)}
				</div>
				<button className="damagesModal__saveBtn" onClick={onClick}>
					Guardar
				</button>
			</div>
		</div>
	);
};

export default DamageWindow;
