// Vehicles Damages
import VehicleDamages from './components/vehicle-damages';
import { getDamageArea, getDamageAreaAsNumber } from './components/vehicle-damages/utils';

// Sketch
import Sketch from './components/sketch/Sketch';
import SketchProvider from './components/sketch/SketchContext';
import { SceneTypes } from './components/sketch/interfaces';

// Location
import Map from './components/location/Map';
import LocationSearch from './components/location/Search';
import { Location } from './components/location/interfaces';
import { reverseGeocode } from './components/location/Geocoder';

// Camera
import Camera from './components/camera';
import { Photo, Validation } from './components/camera/interfaces';
import { Damages, Damage } from './components/vehicle-damages/interfaces';
import { defaultPhotos } from './components/camera/defeault-photos';
import CameraTooltip, { getTooltipContents } from './components/camera-tooltip/CameraTooltip';
import PhotoId from './enums';
declare let CImgTool: any;

// Initalize Image Tool
if (typeof CImgTool !== 'undefined') {
	CImgTool.Lib.InitOnlyCore();
	CImgTool.Lib.SetToleranceBlurry(16.5);
}

// Vehicle Damages
export { VehicleDamages, Damages, Damage, getDamageArea, getDamageAreaAsNumber };
// Camera
export { Camera, Photo, defaultPhotos, Validation };

export { CameraTooltip, getTooltipContents, PhotoId };

// Sketch exports
export { Sketch, SketchProvider, SceneTypes };

// Location exports
export { Map, LocationSearch, Location, reverseGeocode };
