enum PhotoId {
	PANORAMIC = 'panoramic_photo_1',

	INSURED_VEHICLE = 'insured_accident',
	INSURED_VEHICLE_LICENSE_PR = 'insured_license_vehicle', // NOTE: leaving id as is
	INSURED_VEHICLE_ODOMETER = 'insured_odometer',
	INSURED_VEHICLE_MARBETE = 'insured_marbete',
	INSURED_VEHICLE_VIN = 'insured_vin',
	INSURED_VEHICLE_PLATE_PR = 'insured_plate',
	INSURED_VEHICLE_PLATE_CR = 'insured_plate_cr', // NOTE: added id
	INSURED_VEHICLE_PLATE_PA = 'insured_plate_pa', // NOTE: added id
	INSURED_VEHICLE_PASSENGER_SIDE = 'insured_vehicle_passenger_side',
	INSURED_VEHICLE_DRIVER_SIDE = 'insured_vehicle_driver_side',
	INSURED_VEHICLE_BACK = 'insured_vehicle_back',
	INSURED_VEHICLE_FRONT = 'insured_vehicle_front',
	INSURED_VEHICLE_DAMAGE_1 = 'insured_damage_1',
	INSURED_VEHICLE_DAMAGE_2 = 'insured_damage_2',
	INSURED_VEHICLE_DAMAGE_3 = 'insured_damage_3',
	INSURED_VEHICLE_DAMAGE_4 = 'insured_damage_4',
	// TODO: These are the same names as Injured
	INSURED_VEHICLE_INTERIOR_1 = 'internal_photo_1', // NOTE: colliding id
	INSURED_VEHICLE_INTERIOR_2 = 'internal_photo_2', // NOTE: colliding id
	INSURED_LICENSE_PR = 'insured_license', // NOTE: leaving id just in case
	INSURED_LICENSE_PA = 'insured_license_pa', // NOTE: changed id
	INSURED_LICENSE_CR = 'insured_license_cr', // NOTE: changed id
	INSURED_LICENSE_OR_CEDULA_PA = 'insured_license_or_cedula_pa',
	INSURED_POLICIVE_PART_PA = 'insured_policivePart_pa',
	INSURED_POLICIVE_PART_CR = 'insured_policivePart_cr',
	INSURED_TECHNICAL_REVIEW = 'insured_technical_review',
	INSURED_CIRCULATION_RIGHTS = 'insured_circulation_rights',
	INSURED_FUD = 'insured_fud',

	INJURED_VEHICLE = 'injured_accident',
	INJURED_VEHICLE_LICENSE_PR = 'injured_license_vehicle', // NOTE: leaving id just in case
	INJURED_VEHICLE_ODOMETER = 'injured_odometer',
	INJURED_VEHICLE_MARBETE = 'injured_marbete',
	INJURED_VEHICLE_VIN = 'injured_vin',
	INJURED_VEHICLE_PLATE_PR = 'injured_plate',
	INJURED_VEHICLE_PLATE_CR = 'injured_plate_cr', // NOTE: added id
	INJURED_VEHICLE_PLATE_PA = 'injured_plate_pa', // NOTE: added id
	INJURED_VEHICLE_PASSENGER_SIDE = 'injured_vehicle_passenger_side',
	INJURED_VEHICLE_DRIVER_SIDE = 'injured_vehicle_driver_side',
	INJURED_VEHICLE_BACK = 'injured_vehicle_back',
	INJURED_VEHICLE_FRONT = 'injured_vehicle_front',
	INJURED_VEHICLE_DAMAGE_1 = 'injured_damage_1',
	INJURED_VEHICLE_DAMAGE_2 = 'injured_damage_2',
	INJURED_VEHICLE_DAMAGE_3 = 'injured_damage_3',
	INJURED_VEHICLE_DAMAGE_4 = 'injured_damage_4',
	INJURED_VEHICLE_INTERIOR_1 = 'internal_photo_1', // NOTE: leaving id just in case
	INJURED_VEHICLE_INTERIOR_2 = 'internal_photo_2', // NOTE: leaving id just in case
	INJURED_LICENSE_PR = 'injured_license', // NOTE: leaving id just in case
	INJURED_LICENSE_PA = 'insured_license_pa', // NOTE: changed id
	INJURED_LICENSE_CR = 'insured_license_cr', // NOTE: changed id

	COUNTERPART_VEHICLE = 'counterpart_accident',
	COUNTERPART_VEHICLE_BACK = 'counterpart_vehicle_back',
	COUNTERPART_VEHICLE_DAMAGE_1 = 'counterpart_damage_1',
	COUNTERPART_VEHICLE_DAMAGE_2 = 'counterpart_damage_2',
	COUNTERPART_VEHICLE_DAMAGE_3 = 'counterpart_damage_3',
	COUNTERPART_REGISTRO_UNICO = 'counterpart_registration',
	COUNTERPART_CEDULA = 'counterpart_id',
	COUNTERPART_LICENSE_PA = 'counterpart_license_pa', // NOTE: changed id!
	COUNTERPART_POLICY = 'counterpart_policy_id',
	COUNTERPART_FUD = 'counterpart_fud',

	// Note: How do we parametrize these, so that we can have dynamically many?
	OTHERS_1 = 'others_1',
	OTHERS_2 = 'others_2',
	FIXED_OBJECT = 'fixed_object',
}

export default PhotoId;
