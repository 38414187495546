import Geocode from 'react-geocode';

Geocode.setApiKey(process.env.REACT_APP_GMAPS_KEY || '');

// Tiny wrapper/file to provide single entry-point to the .setApiKey call above.
export async function reverseGeocode(lat: string, lng: string): Promise<string> {
	return Geocode.fromLatLng(lat, lng).then(findBestAddress);
}

// eslint-disable-next-line camelcase
function findBestAddress({ results }: { results: { formatted_address: string }[] }): string {
	return results[0].formatted_address;
}
